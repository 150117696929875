<template>
<div>
  <button @click="test">test</button>
  <CarouselElement :img-urls="imgs" />
  <ProductCardElement :product="product" />
  <ProductCardCatalogElement :product="product" />
  <RangeInputElement :min="-10" :max="300" />
  <br/>
  <ProductsCarouselElement :products="products" />


  <div>
    <ModalWindowElement v-if="showModal"  :close="closeModal" >
      <div>
        Создание категории
      </div>
      <div class="category-input">
        <input />
      </div>
    </ModalWindowElement>
    <button @click="showModal = true"> show moada</button>
  </div>

  <div>
    .
  </div>
  <div style="font-family: 'Open Sans'">
    OPEN SANS, Open Sans, open sans
  </div>
  <div>
    OPEN SANS, Open Sans, open sans
  </div>

</div>
</template>

<script setup>
import httpClient from "@/httpClient/httpClient";
import ProductsCarouselElement from "@/elements/Carousels/ProductsCarouselElement"
import RangeInputElement from "@/elements/Inputs/RangeInputElement"
import CarouselElement from "@/elements/Carousels/CarouselElement"
import ProductCardElement from "@/elements/Cards/ProductCardElement"
import ProductCardCatalogElement from "@/elements/Cards/ProductCardCatalogElement"
import {reactive, ref} from "vue";
import {Product} from "@/models/Product.ts"
import ModalWindowElement from "@/elements/Panels/ModalWindowElement";


const showModal = ref(false)

const closeModal = ()=>{
  showModal.value = false
}

let productModel = new Product(123,'sa',1,5);
const product = reactive(
    productModel
)


const imgs = reactive([
    'https://catherineasquithgallery.com/uploads/posts/2021-02/1612503153_91-p-koshka-na-serom-fone-134.jpg',
    'https://www.wallpaperflare.com/static/341/974/440/kitten-grass-face-furry-wallpaper.jpg',
    'https://catherineasquithgallery.com/uploads/posts/2021-02/1612503153_91-p-koshka-na-serom-fone-134.jpg'
])


const products = [
  {
    id: 1,
    name: 'sas',
    description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
    imgUrls: imgs
  },
  {
    id: 2,
    name: 'sas',
    description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
    imgUrls: imgs
  },
  {
    id: 3,
    name: 'sas',
    description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
    imgUrls: imgs
  },{
    id: 4,
    name: 'sas',
    description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
    imgUrls: imgs
  },
  {
    id: 5,
    name: 'sas',
    description: 'ksqfh owijb o jwefoibj ijw joei jfoi jofj oefi joi je ojj',
    imgUrls: imgs
  },


]


const test = async ()=>{
  let res = await httpClient.Post('/auth/register', {
    "email": "kesha.tkachenko2017@mail.ru",
    "password": "form.password",
    "name": "string",
    "type": "Default"
  })
  console.log(res)
}

</script>


<style scoped>

</style>