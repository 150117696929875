import { createRouter,createWebHashHistory } from 'vue-router'
import RegistrationView from '../views/Authorization/RegistrationView.vue'
import AuthorizationView from '../views/Authorization/AthorizationView.vue'
import EmailConfirmedView from "@/views/Authorization/EmailConfirmedView.vue"
import TestView from '../views/TestView'
import PasswordRecoveryView from '../views/Authorization/PasswordRecoveryView'
import PasswordRecoveryCodeView from '../views/Authorization/PasswordRecoveryCodeView'
import PasswordRecoveryPasswordView from '../views/Authorization/PasswordRecoveryPasswordView'
import MainView from '../views/MainView'
import UserAgreementView from '../views/UserAgreementView'
import AllRoutes from "@/views/AllRoutes";
import CatalogView from '../views/Catalog/CatalogView'
import FavouriteView from '../views/Favourite/FavouriteView'
import ArView from '../views/ArView'
import BasketView from '../views/Basket/BasketView'
import ProfileView from '../views/Profile/ProfileView'
import CatalogSearchView from '../views/Catalog/CatalogSearchView'
import CatalogSectionView from '../views/Catalog/CatalogSectionView'
import CatalogSearchFiltersView from '../views/Catalog/CatalogSearchFiltersView'
import ProductView from '../views/Catalog/ProductView'
import ProductImagesView from '../views/Catalog/ProductImagesView'
import FavouriteCategoriesView from '../views/Favourite/FavouriteCategoriesView'
import AddingToCategoryView from '../views/Favourite/AddingToCategoryView'
import FavouriteCategoryView from '../views/Favourite/FavouriteCategoryView'
import BasketOrderPickupView from '../views/Basket/BasketOrderPickupView'
import BasketOrderDeliveryView from '../views/Basket/BasketOrderDeliveryView'
import OrderPickupView from '../views/Basket/OrderPickupView'
import PaymentPickupView from '../views/Basket/PaymentPickupView'
import OrderDeliveryView from '../views/Basket/OrderDeliveryView'
import PaymentDeliveryView from '../views/Basket/PaymentDeliveryView'
import OrderProcessingView from '../views/Basket/OrderProcessingView'
import PersonalDataView from '../views/Profile/PersonalDataView'
import PasswordChangeView from '../views/Profile/PasswordChangeView'
import HelpView from '../views/Profile/HelpView'
import PrivacyPolicyView from '../views/Profile/PrivacyPolicyView'
import CityView from '../views/Profile/CityView'
import OrdersView from '../views/Profile/OrdersView'
import OrderReturnsView from '../views/Profile/OrderReturnsView'
import MessageDesignerView from '../views/Profile/MessageDesignerView'
import DesignerView from '../views/Profile/DesignerView'
import DesignerWorksView from '../views/Profile/DesignerWorksView'
import DesignerPaymentView from '../views/Profile/DesignerPaymentView'
import ConsultationPayedView from '../views/Profile/ConsultationPayedView'
import ChatView from '../views/Profile/ChatView'
import ProductAboutView from '../views/Profile/ProductAboutView'
import DesignerProfileView from '../views/Profile/DesignerProfileView'


const routes = [
  {
    path: '/profile/designer_profile/:id',
    name: 'designer_profile',
    component: DesignerProfileView,
    props: true
  },
  {
    path: '/profile/consultation/chat/:id',
    component: ChatView,
    name: 'consultation_chat',
    props: true
  },
  {
    path: '/profile/consultation/payed/:id',
    component: ConsultationPayedView,
    name: 'consultation_payed',
    props: true
  },
  {
    path: '/profile/designer/payment/:id',
    component: DesignerPaymentView,
    name: 'designer_payment',
    props: true
  },
  {
    path: '/profile/designer/works/:id',
    component: DesignerWorksView,
    name: 'designer_works',
    props: true
  },
  {
    path: '/profile/designer/:id',
    component: DesignerView,
    name: 'designer',
    props: true
  },
  {
    path: '/profile/message_designer',
    component: MessageDesignerView
  },
  {
    path: '/profile/order_returns',
    component: OrderReturnsView
  },
  {
    path: '/profile/product_about',
    component: ProductAboutView
  },
  {
    path: '/profile/orders',
    component: OrdersView
  },
  {
    path: '/profile/city',
    component: CityView
  },
  {
    path: '/profile/privacy',
    component: PrivacyPolicyView
  },
  {
    path: '/profile/help',
    component: HelpView
  },
  {
    path: '/profile/password',
    component: PasswordChangeView
  },
  {
    path: '/profile/personal',
    component: PersonalDataView
  },
  {
    path: '/order/processing/:orderId',
    component: OrderProcessingView
  },
  {
    path: '/payment/delivery',
    component: PaymentDeliveryView
  },
  {
    path: '/order/delivery',
    component: OrderDeliveryView
  },
  {
    path: '/payment/pickup',
    component: PaymentPickupView
  },
  {
    path: '/order/pickup',
    component: OrderPickupView
  },
  {
    path: '/basket/order/delivery',
    component: BasketOrderDeliveryView
  },
  {
    path: '/basket/order/pickup',
    component: BasketOrderPickupView
  },
  {
    path: '/favourite/category/:category',
    component: FavouriteCategoryView,
    name: 'favouriteCategory',
    props: true
  },
  {
    path: '/addingToCategory/:category',
    component: AddingToCategoryView,
    name: 'addingToCategory',
    props: true
  },
  {
    path: '/productImages/:id',
    component: ProductImagesView,
    name: 'productImages',
    props: true
  },
  {
    path: '/product/:id/:color',
    component: ProductView,
    name: 'product',
    props: true
  },
  {
    path: '/catalog_search_filters/:section',
    component: CatalogSearchFiltersView,
    name: 'catalog_search_filters',
    props: true
  },
  {
    path: '/catalog_section/:section',
    component: CatalogSectionView,
    name: 'catalog_section',
    props: true
  },
  {
    path: '/catalog_search',
    component: CatalogSearchView
  },
  {
    path: '/profile',
    component: ProfileView
  },
  {
    path: '/basket',
    component: BasketView
  },
  {
    path: '/ar',
    component: ArView
  },
  {
    path: '/favourites',
    component: FavouriteView
  },
  {
    path: '/favourites/categories',
    component: FavouriteCategoriesView
  },
  {
    path: '/catalog',
    component: CatalogView
  },
  {
    path: '/',
    component: MainView
  },
  {
    path: '/register',
    name: 'register',
    component: RegistrationView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthorizationView
  },
  {
    path: '/emailConfirmed',
    name: 'emailConfirmed',
    component: EmailConfirmedView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/test',
    component: TestView
  },
  {
    path: '/password_recovery',
    component: PasswordRecoveryView
  },
  {
    path: '/password_recovery_code',
    component: PasswordRecoveryCodeView
  },
  {
    path: '/password_recovery_password',
    component: PasswordRecoveryPasswordView
  },
  {
    path: '/main',
    component: MainView
  },
  {
    path: '/user_agreement',
    component: UserAgreementView
  },
  {
    path: '/routes',
    component: AllRoutes
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
