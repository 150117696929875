<template>
  <div class="header">
    <div class="header-text">
      Все возможности так же в мобильном приложении
    </div>
    <div>
      <button class="download-btn">
        Скачать
      </button>
    </div>
    <div class="close-btn" @click="closeSelf">
      <img src="@/assets/img/closeIcon.svg" />
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";

const props = defineProps(['closeEvent'])

const downloadLink = ref("")

function getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const insertLink = ()=>{
  let res = getMobileOperatingSystem()
  if(res==="iOS"){
    downloadLink.value = 'https://apple.co/3wVaVR9'
  }else{
    downloadLink.value = 'https://play.google.com/store/apps/details?id=ru.cdoglobal.neuraldesigner'
  }
}

insertLink()

const closeSelf = ()=>{
  props.closeEvent()
}



</script>

<style scoped>

.close-btn{
  margin: auto;
  margin-left: 20px;
  cursor: pointer;
}


.download-btn{
  background-color: #9EADC4;
  border: none;
  height: 30px;
  width: 79px;
  padding: 5px 10px 5px 10px;

  margin-top: 5px;
}
.header {
  background-color: #E5EAF1;
  height: 44px;
  width: 100%;

  padding-top: 5px;

  display: grid;
  grid-template-columns: 2fr 1fr 50px;
}

.header-text {
  height: 30px;

}
</style>