<template>
  <div>
    <iframe class="ymaps-window" id="map_frame"
            :src="ymap_frame_src"></iframe>
  </div>
</template>

<script setup>
import {defineExpose, defineProps, reactive} from "vue";

const props = defineProps(['interactive', 'addressChanged'])

let hash = ''

const addressObj = reactive({
  country: '',
  city: '',
  street: '',
  house: ''
})

// просто не верю js
if (props.interactive === true) {
  hash = "#interactive";

  window.addEventListener("message", (e) => {
    console.log("window message");
    console.log(e);

    let address = {
      country: '',
      city: '',
      street: '',
      house: ''
    }

    if(e.data!==undefined){
      let addressArr = e.data.split(',');

      [address.country, address.city, address.street, address.house] = addressArr;

      addressObj.city = address.city;
      addressObj.country = address.country;
      addressObj.street = address.street;
      addressObj.house = address.house;

      console.log(address);

      if(props.addressChanged!==null&&props.addressChanged!==undefined){
        props.addressChanged(addressObj)
      }
    }







  });
}


const ymap_frame_src = window.location.origin + '/ymap_snippet.html' + hash

const SetGeoPoint = (point) => {
  let map_frame = document.getElementById('map_frame')
  let coordinatesStr = JSON.stringify(point)
  map_frame.contentWindow.postMessage('{"coordinates": ' + coordinatesStr + '}', "*")

}


defineExpose({SetGeoPoint})

</script>

<style scoped>

.ymaps-window {
  border: none;
  height: 260px;
  width: 100%;
}

</style>