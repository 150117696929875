<template>
  <div class="header-container">
    <router-link to="/profile">
      <div class="back-icon">
        <img class="icon" src="/icons/left-arrow.svg"/>
      </div>
    </router-link>

    <div class="heading">
      {{memberName}}
    </div>
    <div class="options-icon">
      ...
    </div>
  </div>
  <div ref="msgContainer" class="chat-container">
    <div v-for="(msg, i) in messages" :key="i" style="margin-top: 10px;">
      <div v-if="msg.author===props.username"
           class="message-to">
        <div v-if="msg.imageId===null && msg.panoramaId === null">
          {{ msg.text }}
        </div>
        <div v-else-if="msg.type==='img'">
          <img class="message-img" :src="msg.imgUrl"/>
        </div>
      </div>
      <div v-else
           class="message-from">
        <div v-if="msg.imageId===null && msg.panoramaId === null">
          {{ msg.text }}
        </div>
        <div v-else-if="msg.type==='img'">
          <img class="message-img" :src="msg.imgUrl"/>
        </div>
      </div>
    </div>
  </div>
  <BottomFixedPanelElement height="60">
    <div class="message-input-container">
      <div class="img-btn">
        <img src="../../assets/img/profile/camera-icon.svg"/>
      </div>
      <div class="message-input-field">
        <input v-model="message" class="message-input" :placeholder="placeholderText"/>
      </div>
      <div @click="sendMsg" class="send-btn">
        <img src="../../assets/img/profile/send-icon.svg"/>
      </div>
    </div>
  </BottomFixedPanelElement>
</template>

<script setup>
import {defineProps, ref} from "vue";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement";
import chatService from "@/services/chatService";
import designerService from "@/services/designerService";

const props = defineProps(['username', 'user', 'memberId'])

console.log("props.memberId", props.memberId)

const message = ref("")
const messages = ref([])
const msgCount = ref(null)

const memberName = ref(props.username)

//const member = ref(props.username)

const msgContainer = ref(null)

const scrollToBottom = () => {
  console.log(msgContainer.value)
  msgContainer.value.scrollTop = 1940
}

const loadMessages = () => {
  chatService.GetHistory(props.memberId).then(res => {
    console.log("getHistory", res)

    if (msgCount.value === null) {
      msgCount.value = res.messageEntities.length
      messages.value = res.messageEntities
      setTimeout(scrollToBottom, 200)
    } else {
      if (msgCount.value < res.messageEntities.length) {
        messages.value = res.messageEntities
        msgCount.value = res.messageEntities.length
        setTimeout(scrollToBottom, 200)
      }
    }

  })
}

loadMessages()

setInterval(loadMessages, 2000)


const placeholderDefaultMsg = "Начните писать сообщение..."
//const placeholderNotPayedMsg = "Оплатите консультацию для отправки сообщений"

const placeholderText = ref(placeholderDefaultMsg)

const sendMsg = () => {
  chatService.SendMsg({
    memberId: props.memberId,
    message: {
      text: message.value,
      imageId: 0,
      panoramaId: 0
    }
  }).then(res => {
    console.log("SendMsg", res)
  })

  message.value = ""
}

const loadMemberData = async ()=>{
  let isDesigner = await designerService.IsDesigner()
  if(!isDesigner){
    let res = await designerService.GetDesigner(props.memberId)
    memberName.value = res.designer
  }
}

loadMemberData()

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.img-btn {
  margin-top: 15px;
}

.send-btn {
  margin-right: 0;
  margin-left: auto;
  margin-top: 15px;
  cursor: pointer;
}

.message-input-container {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.message-input {
  outline: none;
  border: none;

  height: 50px;
  width: 300px;
  margin-left: 10px;

}

.message-img {
  width: 280px;
  height: 200px;
}

.message-to {
  margin-left: auto;
  width: fit-content;
  max-width: 280px;
  background-color: #E5EAF1;
  padding: 5px;
}

.message-from {
  width: fit-content;
  max-width: 280px;
  background-color: #F2F2F2;
  padding: 5px;
}

.options-icon {

}

.back-icon {

}

.heading {
  margin: auto;


  font-family: Open Sans;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

.header-container {
  display: flex;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.chat-container{
  padding: 10px;
}

</style>