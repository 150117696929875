<template>
  <div class="container">
    <div class="header">
      <h2 class="heading">
        <img src="/icons/logo.svg" />
      </h2>
      <EnterButtonElement style="margin-left: auto;margin-top: 10px;"/>
    </div>

    <div>
      <div class="top-text">
        Наш платформа - дизайнер с искусственным интеллектом,
        призвана помочь людям, не имеющим возможность нанять
        профессионального дизайнера, но желающим создать уникальные
        интерьеры у себя дома
      </div>
      <div class="tablet-container">
        <div>
          <div class="tablet-color-changer">
            <div class="tablet-color-changer-text">
              Цвет
            </div>
            <div style="display: flex;">
              <div :style="ohraStyle">
                <button
                    @click="(e)=>{changeSofa('ohra');}"
                    class="clr-changer-ohra">
                  Охра
                </button>
              </div>
              <div :style="greyStyle">
                <button
                    @click="(e)=>{changeSofa('grey');}"
                    class="clr-changer-grey">
                  Серый
                </button>
              </div>


            </div>
          </div>
          <img v-if="sofa==='ohra'" class="flexible-wh" src="../assets/img/sofaOhra.png"/>
          <img v-else class="flexible-wh" src="../assets/img/sofaGray.png"/>
        </div>
        <div class="tablet-text-container">
          <div class="tablet-text">
            Подберем предметы интерьера
            по Вашим пожеланиям
          </div>
        </div>

      </div>
      <div class="tablets-container-bottom">
        <div>
          <img class="tablet-bottom" src="../assets/img/telephone.png"/>
        </div>
        <div style="padding-left: 30px;">
          <img class="tablet-bottom" style="width: 530px;" src="../assets/img/sofa.png"/>
        </div>


      </div>
    </div>

    <div class="bottom-text">
      Объединяем в себе технологии искусственного интеллекта,
      виртуальной и дополненной реальности,
      что позволит нашим пользователям упростить процесс ремонта и создания интерьеров
    </div>

    <div class="bottom-tablet-container">
      <div class="bottom-tablet-text">
        Откройте этот сайт на смартфоне или скачайте приложение,
        чтобы использовать дополненную реальность на максимум
      </div>
      <div>
        <img style="margin-right: 20px;margin-bottom: 10px;" src="@/assets/img/appStore.svg"/>
        <img src="@/assets/img/playMarket.svg"/>

      </div>
    </div>

    <div class="questions-text">
      Если у Вас есть вопросы и предложения - напишите нам
    </div>

    <div>
      <div>
        <input
            class="text-field"
            placeholder="Ваше имя"/>
      </div>
      <div>
        <input
            class="text-field"
            placeholder="Email для обратной связи"/>
      </div>
      <div>
        <textarea
            class="text-area"
            placeholder="Опишите свои вопросы, замечания или предложения о сотрудничестве"/>
      </div>

      <div class="send-form-btn">
        <button class="app-blue-btn" style="margin: auto;">Отправить</button>
      </div>


    </div>

  </div>
</template>

<script setup>
import {ref} from "vue";
import EnterButtonElement from "@/elements/Buttons/EnterButtonElement";

const sofa = ref('ohra');

const ohraStyle = ref('margin-right: 10px;');
const greyStyle = ref('');

const border = 'border: 4px solid white;'

const changeSofa = (color) => {
  if (color === 'ohra') {
    sofa.value = 'ohra'
    ohraStyle.value = 'margin-right: 10px;' + border
    greyStyle.value = ''
  } else {
    sofa.value = 'grey'
    greyStyle.value = border
    ohraStyle.value = 'margin-right: 10px;'
  }
}

</script>

<style src="@/assets/styles/buttons.css"></style>
<style scoped>

.clr-changer-grey {
  background-color: #C0C0C0;
  height: 40px;
  width: 68px;
  border: none;
  padding: 10px 15px 10px 15px;

}

.clr-changer-ohra {
  background-color: #FFD154;


  height: 40px;
  width: 68px;
  border: none;
  padding: 10px 15px 10px 15px;

}


.tablet-color-changer-text {
  font-family: Open Sans, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  color: #FFFFFF;
  margin-bottom: 5px;
}

.tablet-color-changer {
  position: relative;
  text-align: left;
  height: 0;

  top: 10px;
  left: 15px;
}

.send-form-btn {
  margin-top: 20px;
  margin-bottom: 60px;
}

.text-area {
  width: 445px;
  height: 140px;
  resize: none;
}

.text-field {
  height: 40px;
  width: 450px;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;

  margin-bottom: 10px;
}

.questions-text {
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;

  color: #000000;

  margin-top: 80px;
  margin-bottom: 10px;
}


.bottom-tablet-text {
  font-family: Montserrat, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;

  height: 120px;
  width: 731.25px;

  color: #000000;


}

.bottom-tablet-container {
  display: flex;
  background-color: #9EADC4;

  padding: 30px;
}

.top-text {
  font-family: Open Sans, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 30px;
}

.bottom-text {
  font-family: Open Sans, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 20px;
  margin-bottom: 80px;
}

.tablet-text {
  width: 320px;
  height: 290px;
  margin: auto;

}

.flexible-wh {
  width: 610px;
  height: 100%;
}

.tablet-container {
  display: flex;
  height: 290px;
  margin-bottom: 20px;
}

.tablets-container-bottom {
  display: flex;
}

.tablet-bottom {
  height: 615px;
  width: 450px;
}

.tablet-text-container {
  background-color: #757577;
  font-family: Montserrat, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;

  width: 260px;
}

.container {
  margin: auto;
  width: 930px;
}

.header {
  display: flex;
}

</style>