<template>
  <div :style="divider"></div>
  <div class="bottom-panel" :style="style">
    <slot/>
  </div>
</template>

<script setup>
import {reactive, defineProps, ref} from "vue";

const props = defineProps({
  height: Number,
  offsetY: Number
})


let containerHeight = window.screen.height

console.log(containerHeight, props.height)

let offsetY = 0

if (props.offsetY !== null && props.offsetY !== undefined) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  offsetY = props.offsetY
}

const divider = ref('')

const styleObject = reactive({
  height: 20
})

const calculate = () => {
  return 'top: ' + (containerHeight - styleObject.height - offsetY) + 'px;' + 'height: ' + styleObject.height + 'px;'
}

const style = ref(calculate())


if (props.height === undefined || props.height === '' || props.height === 0) {
  styleObject.height = 20
} else {
  // eslint-disable-next-line vue/no-setup-props-destructure
  styleObject.height = props.height
}

style.value = calculate()

divider.value = 'height: ' + (styleObject.height - 1 + 10) + 'px;'

</script>

<style scoped>

.bottom-panel {
  position: fixed;
  background-color: #FFFFFF;
  width: 100%;

  box-shadow: 0 -2px 15px black;

  z-index: 9999;
}

</style>