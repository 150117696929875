<template>
  <div>
    <HeaderWithBackIconElement heading="Доставка" back-link="/basket/order/delivery" />

    <div class="app-content-container">

      <InputElement class="standart-margin"
                    role="listbox"
                    type="dropdown"
                    :drop-list="['Spb', 'Moscow']"
                    label="Город" v-model="form.city" />

      <InputElement type="iconized" :icon-clicked="()=>setGeoPoint()"
                    class="standart-margin" label="Улица" v-model="form.street" />

      <div class="flex standart-margin">
        <div style="width: 46%;margin-right: auto;">
          <InputElement label="Дом" v-model="form.house" />
        </div>
        <div style="width: 46%;" >
          <InputElement label="Этаж" v-model="form.floor" />
        </div>
      </div>



      <YMapElement ref="ymap" :interactive="true" :address-changed="addressChanged" />

      <div class="app-grey-text" style="margin-top: 10px;margin-bottom: 10px;">
        КОНТАКТНЫЕ ДАННЫЕ
      </div>

      <InputElement class="standart-margin" label="Email" v-model="form.email" />
      <InputElement class="standart-margin" label="Имя" v-model="form.firstName" />
      <InputElement class="standart-margin" label="Фамилия" v-model="form.lastName" />

      <div class="app-grey-text" style="margin-top: 10px;margin-bottom: 10px;">
        Время доставки
      </div>

      <TextAreaElement
          width="100%"
          class="standart-margin"
          label="Комментарий (необязательно)" v-model="form.comment" />

      <div class="app-grey-text flex" style="margin-top: 20px;">
        <div>
          Общий вес
        </div>
        <div class="right-text">
          {{store.state.orderDetails.summaryWeight}} кг
        </div>
      </div>


      <div class="app-bold-text flex">
        <div>
          Общая стоимость
        </div>
        <div class="right-text">
          {{store.state.orderDetails.summaryCost}}  ₽
        </div>
      </div>

      <button @click="sendOrder" class="app-blue-btn standart-size-btn">
        Отправить заказ
      </button>

    </div>

  </div>
</template>

<script setup>
import {reactive,ref} from "vue";
import store from "@/store";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import TextAreaElement from "@/elements/Inputs/TextAreaElement";
import YMapElement from "@/elements/YMapElement";
import basketService from "@/services/basketService";
import router from "@/router";


const ymap = ref(null)

const selectedMarket = reactive({coordinates: [0, 0], address: ''})

// const selectMarket = (market) => {
//   console.log(market)
//   form.address = market.address
//   selectedMarket.coordinates = market.coordinates
// }

const setGeoPoint = () => {
  console.log(selectedMarket)
  ymap.value.SetGeoPoint(selectedMarket.coordinates)

}


const form = reactive({
  city: '',
  street: '',
  house: '',
  floor: '',
  email: '',
  firstName: '',
  lastName: '',
  deliveryDate: '',
  deliveryTime: '',
  comment: '',
  country: '',
})


const addressChanged = (address)=>{
  console.log('address')
  console.log(address)
  if(address.country!==undefined){
    form.country = address.country
  }
  if(address.city!==undefined){
    form.city = address.city
  }
  if(address.street!==undefined){
    form.street = address.street
  }
  if(address.house!==undefined){
    form.house = address.house
  }
}


const sendOrder = () => {
  basketService.CreateOrder(form).then(res=>{
    console.log("order res - ", res)

    store.commit('updateOrderDetails', {
      summaryWeight: 0,
      summaryCost: 0,
    })

    if(res.status.toLowerCase()==="ok"){
      store.commit("setOrderNumber", res.id)
      router.push("/order/processing/"+res.id)
    }else{
      alert("smthng wrong")
    }
  })
}


// const marketList = reactive([
//   {
//     address: 'sasl 2',
//     coordinates: [1, 1]
//   },
//   {
//     address: 'dsasl 1',
//     coordinates: [10, 10]
//   },
//   {
//     address: 'mosc',
//     coordinates: [55.7, 37.6]
//   },
// ])


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.address {

  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 5px;
  margin-left: 2px;
}

.standart-margin{
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>