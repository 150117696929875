<template>
  <div class="app-content-container">
    <div class="catalog-search-container">
      <router-link to="/catalog_search">
        <input class="catalog-search" placeholder="Поиск"/>
      </router-link>
    </div>
    <div class="cards-container">
      <div
          class="category-container"
          v-for="(category, i) in catalogCategories"
          :key="i">
        <router-link :to="'/catalog_section/'+ category.serviceName">
          <div class="category-img-container">
            <img class="category-img" :src="category.img"/>
          </div>
          <div class="category-label">
            {{ category.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive} from "vue";


const catalogCategories = reactive(
    [
      {
        serviceName: 'chairs',
        name: 'Стулья',
        img: '/catalog/chairs.png'
      },
      {
        serviceName: 'floors',
        name: 'Полы',
        img: '/catalog/floor.png'
      },
      {
        serviceName: 'lighting',
        name: 'Освещение',
        img: '/catalog/lighting.png'
      },
      {
        serviceName: 'soft_furniture',
        name: 'Мягкая мебель',
        img: '/catalog/softFurniture.png'
      },
      {
        serviceName: 'storages',
        name: 'Хранилище',
        img: '/catalog/storages.png'
      },
      {
        serviceName: 'tables',
        name: 'Столы',
        img: '/catalog/tables.png'
      },
      {
        serviceName: 'walls',
        name: 'Стены',
        img: '/catalog/walls.png'
      },
    ]
)

</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.category-container {

}

.catalog-search-container{
  padding-left: 10px;
}

a{
  text-decoration: none;
  color: black;
}

.category-label {
  font-family: Open Sans, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: left;


  width: 165px;
  margin: auto;
}

.category-img-container {
  max-height: 165px;
  max-width: 165px;
}

.category-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.catalog-container {

}

.catalog-search-container {
  max-height: 35px;
  max-width: 345px;
  border-radius: 0;
  padding: 10px;
  padding-left: 0;
  margin: auto;
}

.catalog-search {
  width: 100%;
  height: 35px;
  background-color: rgba(242, 242, 242, 0.93);
  border: none;
}

.catalog-search::placeholder {
  padding-left: 10px;
}


.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  row-gap: 10px;
}

</style>