<template>
  <div :style="'--width: '+width+'px;--height: '+height+'px;'">
    <div style="height: 0;">
      <div class="go-back-btn on-img-icon" @click="goBack">

        <img class="icon-img" src="/icons/left-arrow.svg"/>

      </div>
      <div class="media-btn on-img-icon">
        <img class="icon-img" src="/icons/media.svg"/>
      </div>
      <div
          v-if="product.liked"
          class="like-btn on-img-icon" @click="dislike">
        <img class="icon-img" src="/icons/heart_filled.svg"/>
      </div>
      <div
          v-else
          class="like-btn on-img-icon" @click="like">
        <img class="icon-img" src="/icons/heart.svg"/>
      </div>

      <div class="ar-btn">
        <div>
          <img src="/icons/ar.svg"/>
          Посмотреть в AR
        </div>
      </div>
    </div>
    <CarouselElement
        v-if="!loading"
        @click="clickImg"
        :width="width"
        :height="230"
        :img-urls="product.imgUrls"/>
    <div v-else style="height: 230px;">
      loading please wait...
    </div>

  </div>
  <div class="description-container">


    <div class="flex">
      <div class="price">
        {{ product.price }} ₽
      </div>
      <div class="old-price">
        {{ product.oldPrice }} ₽
      </div>
      <div class="discount">
        {{ Math.round(((product.price - product.oldPrice) / product.oldPrice) * 100) }}%
      </div>
    </div>

    <div class="product-category">
      {{ product.itemType }}
    </div>

    <div class="product-name">
      {{ product.name }}
    </div>

    <div class="header-text">
      ЦВЕТ
    </div>
    <div class="colors">
      <div v-for="(color, i) in product.colorVariants" :key="i" style="margin-right: 10px;">
        <div
            v-if="product.currentColor === color.color"
            class="app-blue-btn lr-paddings">
          {{ color.color }}
        </div>
        <div
            v-else
            @click="()=> changeColor(color)"
            class="app-white-btn lr-paddings">
          {{ color.color }}
        </div>
      </div>
    </div>

    <div class="header-text" style="margin-bottom: 5px;">
      ОПИСАНИЕ
    </div>
    <div class="description">
      {{ product.description }}
    </div>

    <div class="params-grid">
      <div class="header-text">
        Ширина, см
      </div>
      <div>
        {{ product.width }}
      </div>
      <div class="header-text">
        Глубина, см
      </div>
      <div>
        {{ product.depth }}
      </div>
      <div class="header-text">
        Высота, см
      </div>
      <div>
        {{ product.height }}
      </div>
      <div class="header-text">
        Стиль
      </div>
      <div>
        {{ product.style }}
      </div>
    </div>

    <div class="header-text" style="margin-top: 10px;margin-bottom: 5px;">
      ПОХОЖИЕ ТОВАРЫ
    </div>

    <div>
      <ProductsCarouselElement :products="product.similarProducts"/>
    </div>


  </div>

  <BottomFixedPanelElement :height="70">
    <button
        v-if="product.addingCount===0"
        @click="addProduct"
        class="app-blue-btn to-basket-btn">
      <img src="/icons/basket.svg" /> &nbsp;
      В корзину за {{product.price}} ₽
    </button>

    <div v-else class="adding-products-container">
      <div class="adding-products">
        <div @click="removeProduct" class="control-icon">
          <img src="/icons/minus.svg"/>
        </div>
        <div class="counter">
          {{ product.addingCount }}
        </div>
        <div @click="addProduct" class="control-icon">
          <img v-if="product.addingCount !== product.countAvailable" src="/icons/plus.svg"/>
          <img v-else src="/icons/plus_disabled.svg"/>
        </div>
      </div>

      <button @click="()=>router.push('/basket')" class="app-blue-btn basket-link-btn">
        К корзине
      </button>
    </div>
  </BottomFixedPanelElement>

</template>

<script setup>
import store from "@/store";
import router from "@/router";
import {defineProps, ref, reactive} from "vue";
import CarouselElement from '@/elements/Carousels/CarouselElement'
import ProductsCarouselElement from "@/elements/Carousels/ProductsCarouselElement";
import BottomFixedPanelElement from "@/elements/Panels/BottomFixedPanelElement"
import basketService from "@/services/basketService";

const loading = ref(false)

const props = defineProps(['product'])

const product = reactive(props.product)

console.log("prod comp" ,props.product)

const clickImg = () => {
  console.log('clclclc')
  router.push('/productImages/'+props.product.id)
}







const addProduct = () => {
  if (product.addingCount !== product.countAvailable) {
    product.addingCount += 1
    basketService.AddToBasket(product.id, product.currentColor).then(res=>{
      console.log("AddToBasket", res)
    })
  }
}

const removeProduct = () => {
  product.addingCount -= 1
  basketService.RemoveFromBasket(product.id, product.currentColor).then(res=>{
    console.log("RemoveFromBasket", res)
  })
}





const changeColor = (color) => {
  loading.value = true
  product.color = color.color
  product.currentColor = color.color
  product.imgUrls = color.coloredImgUrls
  product.addingCount = color.count

  setTimeout(()=>{ loading.value = false }, 10)


}

const goBack = () => {
  if (store.state.previousPageData.url !== '') {
    router.push(store.state.previousPageData.url)
  } else {
    router.push('/catalog')
  }
}

const like = () => {
  product.liked = true
  console.log('product.liked = true')
}

const dislike = () => {
  product.liked = false
  console.log('product.liked = !true')
}

const width = ref(window.screen.width)
const height = ref(230)

</script>

<style src="../../assets/styles/buttons.css"></style>
<style scoped>

.basket-link-btn{
  width: 165px;
  height: 40px;
  margin-top: 0;
}

.adding-products-container{
  display: flex;
  margin: 15px;
}

.counter{
  width: 40%;
}

.control-icon{
  cursor: pointer;
}

.adding-products{
  padding-top: 10px;
  height: 28px;
  width: 40%;
  display: flex;
  justify-content: center;

  border: 1px solid #9EADC4;

  margin-right: 20px;
  margin-left: 10px;
}

.to-basket-btn{
  height: 40px;
  width: 345px;
  padding: 15px;
  margin: 15px;
}

.params-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  text-align: left;
}

.description {

  font-family: Open Sans, serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 10px;
}

.description-container {
  margin-left: 10px;
  margin-right: 10px;
}


.product-name {


  font-family: Open Sans, serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}


.product-category {


  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

.lr-paddings {
  padding-left: 4px;
  padding-right: 4px;
}

.colors {
  display: flex;
  flex-wrap: wrap;
}

.header-text {
  color: #757577;
  font-family: Open Sans, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

}

.discount {

  height: 20px;
  width: 35px;

  background-color: #9EADC4;

  font-family: Open Sans, serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;

  padding-top: 5px;
}

.old-price {
  text-decoration: line-through;

  margin-left: 5px;
  margin-right: 5px;
  padding-top: 7px;

  font-family: Open Sans, serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #757577;
}

.price {

  font-family: Open Sans, serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.flex {
  display: flex;
}

.ar-btn {
  z-index: 8888;
  position: absolute;
  margin-top: 185px;
  margin-left: 205px;
  background-color: #FFFFFF;
  opacity: 0.8;
  width: 165px;
  height: 30px;
  padding-top: 8px;
}

.icon-img {
  margin-top: 10px;
}

.on-img-icon {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
}

.go-back-btn {
  position: absolute;
  z-index: 9999;

  margin-top: 5px;
  margin-left: 5px;
}

.like-btn {
  position: absolute;
  z-index: 9999;

  margin-top: 5px;
  margin-left: calc(var(--width) - 90px);
}

.media-btn {
  position: absolute;
  z-index: 9999;

  margin-top: 5px;
  margin-left: calc(var(--width) - 45px);
}


</style>