export interface IProduct {
    id: number
    name: string
    price: number
    oldPrice: number
}


export class Product implements IProduct {

    public static Create(product: IProduct):IProduct{
        return product
    }

    constructor(id: number, name: string, price: number,
                oldPrice: number, countAvailable: number,
                addingCount: number, imgUrls: string[], liked: boolean) {
        this.id = id ?? -1
        this.name = name ?? ''
        this.price = price ?? 0
        this.oldPrice = oldPrice ?? 0
        this.countAvailable = countAvailable ?? 0
        this.addingCount = addingCount ?? 0
        this.imgUrls = imgUrls ?? []
        this.liked = liked ?? false
    }

    public id: number
    public name: string;
    public oldPrice: number;
    public price: number;
    public countAvailable: number;
    public addingCount: number;
    public imgUrls: string[];
    public liked: boolean;
}


