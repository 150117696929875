<template>
  <HeaderWithBackIconElement back-link="/" heading="Мои заказы"/>

  <div v-if="isDesigner" class="sections">
    <div style="display: flex;">
      <div :class="{'consultations': state!=='consultations' }" @click="()=>{ changeState('orders') }" style="margin-right: 15px;">
        Заказы
      </div>
      <div :class="state==='consultations'" @click="()=>{ changeState('consultations') }">
        Консультации
      </div>
    </div>
  </div>

  <OrdersComponent v-if="orders!==null && state==='orders' && false" :orders="orders"/>

  <ConsultationsComponent v-if="consultations!==null" :consultations="consultations"/>

  <div v-else>
    идет загрузка...
  </div>
</template>

<script setup>
import OrdersComponent from "@/components/Profile/OrdersComponent";
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
//import exampleTestModels from "@/test/exampleTestModels";
import ordersService from "@/services/ordersService";
import {ref} from "vue";
import designerService from "@/services/designerService";
import ConsultationsComponent from "@/components/Profile/ConsultationsComponent";
import consultationsService from "@/services/consultationsService";

const consultations = ref(null)
const orders = ref(null)
const state = ref("consultations")

// const order_example = {
//   type: 'delivery',
//   number: '14578224',
//   date: '12.06.2022',
//   status: 'в обработке',
//   cost: 7654,
//   address: 'ул. грибоедова д. 3',
//   times: '12:00 - 16:00',
//   items: exampleTestModels.products,
//
// }
//
// const order_example2 = Object.assign(Object.assign({},order_example), {type: 'consultation'})
//
// const orders_examples = [
//   order_example,
//   order_example2
// ]

const isDesigner = ref(false)


ordersService.GetUserOrders().then(res => {
  orders.value = res.orderFroms
  console.log("loaded orders - ", res)
})

const changeState = (newState) => {
  state.value = newState
}

designerService.IsDesigner().then(res => {
  console.log("IsDesigner", res)
  isDesigner.value = res

  if(res){
    consultationsService.GetForDesigner().then(res=>{
      console.log("GetForDesigner", res)
      consultations.value = res.consultations
    })
  }

})


</script>

<style scoped>

a {
  text-decoration: none;
  color: #000000;
}

.underlined {
  display: inline-block;
  text-decoration: none;
  background-image: linear-gradient(to right, #9EADC4, #9EADC4);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size .5s ease;

  height: 25px;
}


.sections {
  margin: auto;
  margin-top: 10px;
  display: flex;

  justify-content: space-around;
}
</style>