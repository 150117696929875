<template>
  <ConsultationPayedComponent v-if="purchaseStatus==='Succeeded'"/>
  <div v-else>
    Ваш заказ в статусе оплаты, пожалуйста подождите пока оплата пройдет
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import ConsultationPayedComponent from "@/components/Profile/ConsultationPayedComponent";
import purchaseService from "@/services/purchaseService";

const props = defineProps(['id'])

const purchaseStatus = ref("")

console.log(props.id)

const checkStatus = () => {
  purchaseService.CheckPurchaseStatus(props.id).then(res => {
    console.log("CheckPurchaseStatus", res)
    purchaseStatus.value = res.consultationPurchaseStatus
  })

  if(purchaseStatus.value!=='Succeeded'){
    setTimeout(checkStatus, 2000)
  }
}

checkStatus()


</script>

<style scoped>

</style>