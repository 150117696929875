<template>
  <div>
    <HeaderWithBackIconElement heading="Персональные данные" back-link="/profile"/>
    <div class="app-content-container">
      <div class="avatar-container">
        <div>
          <img v-if="user.avatarId===null" class="avatar" src="../../assets/img/profile/avatar.png"/>
          <img v-else class="avatar" :src="'/UserFile/download?id='+user.avatarId" />
        </div>
        <div>
          <label for="file" class="standart-size-btn app-white-btn avatar-btn" >Изменить автар</label>
          <input @change="fileChange" ref="fileInp" id="file" type="file" style="visibility: hidden;"  />
        </div>
      </div>

      <InputElement v-model="user.name" class="input-margin" label="Имя"/>
      <InputElement v-model="user.lastName" class="input-margin" label="Фамилия"/>
      <InputElement v-model="user.telephoneNumber" class="input-margin" label="Телефон"/>
      <InputElement v-model="user.email" class="input-margin" label="Email"/>
      <InputElement v-model="user.city" class="input-margin" label="Город"/>

      <button @click="save" class="app-blue-btn standart-size-btn">
        Сохранить
      </button>

      <div @click="goToChangePassword" class="password-change-link">
        Изменить пароль
      </div>



    </div>
  </div>
</template>

<script setup>
import HeaderWithBackIconElement from "@/elements/Panels/HeaderWithBackIconElement";
import InputElement from "@/elements/Inputs/InputElement";
import {reactive, ref} from "vue";
import userService from "@/services/userService";
import router from "@/router";
import httpClient from "@/httpClient/httpClient";


const fileInp = ref(null)




const user = reactive({

  status: "",
  email: "",
  name: "",
  lastName: "",
  avatarId: 0,
  telephoneNumber: "",
  city: ""

})

const reload = () => {

  userService.GetUserData().then(data => {

    user.status = data.status
    user.email = data.email
    user.name = data.name
    user.lastName = data.lastName
    user.avatarId = data.avatarId
    user.telephoneNumber = data.telephoneNumber
    user.city = data.city

    console.log("GetUserData", user)

  })

}

const save = () => {
  userService.SetUserData(user).then(res => {
    console.log("SetUserData", res)
  })
}


const goToChangePassword = ()=>{
  router.push('/profile/password')
}

const fileChange = e => {
  console.log("fileChange", e,fileInp.value.files)
  let fileData = new FormData()
  fileData.append("uploadedFile", fileInp.value.files[0])

  httpClient.UploadUserFile(fileData).then(res => {
    console.log("UploadUserFile", res)

    if(res.status.toLowerCase()==="ok"){
      user.avatarId = res.id
      save()
    }

  })

}

reload()


</script>

<style src="../../assets/styles/all.css"></style>
<style scoped>

.password-change-link {
  text-align: center;

  margin-top: 10px;
  height: 40px;
  text-underline: none;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;

  color: #9EADC4;

}

.input-margin {
  margin-top: 10px;
}

.avatar-btn {
  margin-left: auto;
  width: 200px;
  margin-top: auto;
  margin-bottom: auto;
}

.avatar-container {
  display: flex;
}

.avatar {
  width: 120px;
  height: 120px;
}


</style>